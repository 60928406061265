<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      mini-variant-width="80"
      mini-variant
      width="300"
      expand-on-hover
      clipped
      @transitionend="collapse"
      :style="$vuetify.breakpoint.mobile ? '' : 'z-index: 20 !important;'"
      app
    >
    <v-layout column fill-height>
      <v-list expand>
        <template v-for="item in items">
          <v-list-group
            v-if="item.items && views.includes(item.viewId)"
            :key="item.title"
            v-model="item.active"
            class="pa-3"
            no-action
          >
            <v-icon slot="prependIcon" color="primary" size="22px" >{{ item.icon }}</v-icon>

            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="subItem in item.items">
              <v-list v-if="subItem.items && views.includes(subItem.viewId)" :key="subItem.title" class="py-0">
                <v-list-group
                  :key="subItem.title"
                  v-model="subItem.active"
                  :prepend-icon="subItem.icon"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="ml-6" v-text="subItem.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="subSubItem in subItem.items"
                    :key="subSubItem.title"
                    @click="processAction(subItem.action)"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="subSubItem.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>

              <v-list-item
                v-else-if="views.includes(subItem.viewId)"
                :key="subItem.title"
                @click="processAction(subItem.action)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-item
            v-else-if="views.includes(item.viewId)"
            :key="item.title"
            @click="processAction(item.action)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-layout>

    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      height="90"
      :src="require('@/assets/images/header_bg.svg')"
      dark
      app
    >
      <template v-slot:img="{ props }">
        <div class="header-navigation-button-background-container">
          <img :src="require('@/assets/images/focus_square_150.png')" width="79" height="90" />
        </div>

        <div class="header-background-image-container">
          <v-img
            v-bind="props"
            width="100%"
          ></v-img>
        </div>
      </template>

      <v-toolbar-title
        class="header-title ml-0 pl-0"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down ml-8">
          RI Witness IQ
          <v-divider vertical app class="header-title-divider"></v-divider>
          {{pageTitle}}
        </span>
      </v-toolbar-title>

      <div class="flex-grow-1"></div>
      <span class="header-user-email hidden-xs-only ml-4 mr-4">{{ emailAddress }}</span>

      <v-btn
        class="mr-4"
        icon
        @click.stop="userDrawer = !userDrawer"
      >
        <v-icon>$riwIconSet_user</v-icon>
      </v-btn>

    </v-app-bar>

    <v-navigation-drawer
      v-model="userDrawer"
      floating
      temporary
      width="300"
      class="header-user-menu"
      app
      right
    >
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>$riwIconSet_user</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ emailAddress }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense class="py-0">
        <template v-for="userItem in allUserItems">
          <v-list v-if="userItems.includes(userItem.viewId)" :key="userItem.title" class="py-0">
            <v-list-item
              @click="processAction(userItem.action)"
              :class="userItem.class"
            >
              <v-list-item-icon>
                  <v-icon v-text="userItem.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="userItem.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main class="full_just">
      <v-container
        class="pa-0 smy_fill"
        fluid
      >
        <router-view/>
      </v-container>
      <smyError />
    </v-main>
  </v-app>
</template>

<style>
button:focus {
  outline: 0px;
  outline: 5px auto -webkit-focus-ring-color;
}
</style>

<style scoped>
.v-list-item {
  text-decoration: none;
}
* >>> .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  cursor: pointer !important;
}
* >>> .v-select__slot > input {
  cursor: pointer !important;
}

.header-background-image-container{
  width: 70%;
  float: right;
}

.header-navigation-button-background-container{
  width: 79px;
  float: left;
}

.header-title {
  width: 800px;
}

.header-title-divider {
  height: 55px;
  margin: 0 20px -10px 20px;
  border-color: #F2F2F2
}

.header-user-email {
    font-size: 18px;
    font-weight: 200!important;
}

.header-user-menu {
  z-index: 20 !important;
  padding-top: 90px;
}

</style>

<script>

import homeView from '@/component-models/home-view-component-model.js';

export default homeView;

</script>
